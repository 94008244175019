<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">

      <ClientCostHeader/>

      <v-dialog
          v-model="dialog"
          :scrim="false"
          overlay-color="black"
          overlay-opacity="1"
          attach="#productDescriptionButton"
          width="auto"
      >

        <v-card>

          <v-card-title>
            <div class="d-flex justify-center"><h2>Produktbeschreibung</h2></div>
          </v-card-title>

          <v-card-text>
            <div class="d-flex pa-4">
              <div class="d-flex flex-column pa-4 pt-0">
                <h2 class="main-color-1">eva 3 office</h2>
                <p>inklusiv:</p>
                <ul>
                  <li>Klientenverwaltung</li>
                  <li>Verordnungsverwaltung</li>
                  <li>Leistungsplanung</li>
                  <li>Abrechnung</li>
                  <li>Rechnungsverwaltung</li>
                  <li>Zusatzmodule zur mobilen Erfassung</li>
                  <li>Statistiken</li>
                  <li>inkl. 2 Server-Hostinglizenzen für eva 3 office</li>
                </ul>

              </div>
              <div class="d-flex flex-column pa-4 pt-0">
                <h2 class="main-color-1">eva 3 careplan</h2>
                <p>inklusiv:</p>
                <ul>
                  <li>Pflegedokumentation
                    <ul>
                      <li>Strukturmodell</li>
                      <li>Pflegemodell nach Krohwinkel (ADEL)</li>
                      <li>Wunddokumentation</li>
                    </ul>
                  </li>
                  <li>Arzneimitteldatenbank</li>
                </ul>
              </div>
              <div class="d-flex flex-column pa-4 pt-0">
                <h2 class="main-color-1">Apps</h2>
                <p>inklusiv:</p>
                <ul>
                  <li>eva 3 PDL
                    <ul>
                      <li>Patientenaufnahme</li>
                    </ul>
                  </li>
                  <li>Meine Tour
                    <ul>
                      <li>
                        mobile Leistungserfassung
                      </li>
                      <li>Live-Synchronisation</li>
                    </ul>
                  </li>
                  <li>Eva 3 Messenger</li>
                </ul>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-4">

            <button @click="dialog = false" class="button-standard-continue d-flex align-center justify-center">
              <span class="poppins medium">Schließen</span>
            </button>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="d-flex align-self-start pl-4 mt-8 mb-8">
        <button id="productDescriptionButton" @click="dialog = true"
                class="button-produktbeschreibung ufo-small d-flex align-center justify-center">
          <img
              :src="require('@/assets/images/info.svg')" alt="">
          <span class="poppins semi-bold">Produktbeschreibung</span>
        </button>
      </div>

      <form @submit.prevent="submit">
        <div style="border-bottom: 1px solid #2E6F82" class="d-flex flex-row align-self-start">

          <div class="d-flex align-end" style="width: 25%;">Anzahl Klienten</div>

          <div class="service-header-content" style="border-top-left-radius: 5px; background: #eaf5f3">

            <h2 class="main-color-1">Basic</h2>
            <p class="b2">Support von <br> 09:00 bis 15:00</p>
          </div>

          <div class="service-header-content"
               style="background: #cfe9e4">

            <h2 class="main-color-1">Comfort</h2>
            <p class="b2" style="margin-bottom: 8px">Support von <br> 08:00 bis 17:00 Uhr </p>
            <p class="b2"> Reaktion je nach <br> Klassifizierung binnen <br> 2 bis 24 Stunden</p>

          </div>
          <div class="service-header-content"
               style="border-top-right-radius: 5px; background: #aedbd3">

            <h2 class="main-color-1">Premium</h2>
            <p style="margin-bottom: 8px;" class="b2">Support von <br> 08:00 bis 17:00</p>
            <p class="b2"> Reaktion je nach <br> Klassifizierung binnen <br> 1 bis 8 Stunden</p>

          </div>
        </div>

        <div class="d-flex flex-row align-self-start" v-for="(n, i) in getRowAmount()" :key="n">

          <ServiceContentDescriptionItem :title="getDataByColumn(2)[i]?.Bezeichnung"
                                         :text-line-one="getDataByColumn(2)[i]?.Beschreibung"/>

          <ServiceContentItem
              background-color="#eaf5f3"
              :column-content="getDataByColumn(2)[i]"
          />

          <ServiceContentItem
              background-color="#cfe9e4"
              :column-content="getDataByColumn(3)[i]"
          />

          <ServiceContentItem
              background-color="#aedbd3"
              :column-content="getDataByColumn(4)[i]"
          />
        </div>
      </form>
    </v-card-text>
    <v-card-actions class="actions-container">
      <ButtonStandardBack/>
      <ButtonStandardContinue :prevent-continue="hasValidationFailed()"/>
    </v-card-actions>
  </v-card>

</template>

<script>
import {useFormStore} from "@/stores/FormStore";
import ServiceContentItem from "@/components/items/FormStep2/ServiceContentItem.vue";
import ServiceContentDescriptionItem from "@/components/items/FormStep2/ServiceContentDescriptionItem.vue";
import ButtonStandardContinue from "@/components/items/ButtonStandardContinue.vue";
import ButtonStandardBack from "@/components/items/ButtonStandardBack.vue";
import ClientCostHeader from "@/components/items/ClientCostHeader.vue";

export default {
  components: {
    ClientCostHeader,
    ButtonStandardBack, ButtonStandardContinue, ServiceContentDescriptionItem, ServiceContentItem
  },

  data() {

    const dialog = false;
    const preventionMessage = ''
    return {dialog, preventionMessage};
  },

  setup() {
    const formStore = useFormStore();
    return {formStore}
  },

  methods: {

    hasValidationFailed() {
      if (this.formStore.submittedSteps.step2.selected.length > 0 && this.formStore.numberOfClients > 0) {
        return false;
      } else {
        return true;
      }
    },

    async validate() {
      const {valid} = await this.$refs.form.validate()

      if (valid) alert('Form is valid')
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    getDataByColumn(columnId) {

      if (!columnId) {
        throw Error("ColumnId in Step 2 empty")
      }

      const filteredColumnItems = this.getFilteredColumnItems(columnId);

      for (let i = 0; i < filteredColumnItems.length; i++) {
        let currentOrder = (i + 1).toString();

        if (filteredColumnItems[i].Reihenfolge !== currentOrder) {
          filteredColumnItems.splice(i, 0, {})
        }
      }

      return filteredColumnItems;

    },
    getFilteredColumnItems(columnId) {

      return this.currentMaskItems.filter((el) => {
        return el.Spalte.trim() === columnId.toString()
      });
    },
    getRowAmount() {

      let biggestColumnId = 0
      for (const element of this.currentMaskItems) {
        if (element.Reihenfolge > biggestColumnId.toString()) {
          biggestColumnId = element.Reihenfolge;
        }
      }
      return Number(biggestColumnId);
    }
  },

  computed: {
    currentMaskItems() {
      return this.formStore.getModelCsvConfigByMask.filter((el) => {
        return el.Zielgruppe.trim() === this.formStore.targetGroupMapping[this.formStore.chosenTargetGroup]
      });
    }
  },
}
</script>

<style scoped>

form {
  width: 100%;
}

.service-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  width: 25%;
}

.service-header-content h2 {
  margin-bottom: 8px;
}

.button-produktbeschreibung {
  background-color: #78C6B8;
  color: white;
  margin-right: 4px;
}

.button-produktbeschreibung img {
  min-height: 24px;
  min-width: 24px;
  margin-right: 6px;
}

.step-2-item-header-text {
  margin-bottom: 8px;
}


</style>
