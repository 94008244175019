<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column">

      <v-dialog
          v-model="dialog"
          :scrim="false"
          overlay-color="black"
          overlay-opacity="1"
          attach="#productDescriptionButton"
          width="auto"
      >

        <v-card>

          <v-card-title>
            <div class="d-flex justify-center"><h2>Info</h2></div>
          </v-card-title>

          <v-card-text>
            <div class="d-flex pa-4">
              <div class="d-flex flex-column pa-4 pt-0">

                <ul>
                  <li>Dem Kunden stehen aufgrund monatlicher Leistungsabrechnungen (Rezeptabrechnung) Vergütungsansprüche gegen die opta data Finance GmbH, Berthold-Beitz-Boulevard 461, 45141 Essen zu.</li>
                  <li>Der odITS stehen aufgrund des vorliegenden Vertragsverhältnisse, die in diesem Vertrag näher benannten, fällig werdenden Zahlungsansprüche zu.</li>
                  <li>Zur Erfüllung ihrer Zahlungsverpflichtung und zur Sicherung der Forderung der odITS, tritt der Kunde hiermit unwiderruflich seine gegenwärtigen und künftigen Auszahlungsansprüche gegen die Firma opta data Finance GmbH an die Firma odITS ab. Die Firma odITS nimmt die Abtretung an und ist zur sofortigen Offenlegung der Abtretung bei der opta data Finance GmbH berechtigt. Bis auf Widerruf durch die Firma odITS sind die monatlichen Zahlungen aus den Abrechnungsguthaben an diesen Gläubiger vorzunehmen. Der Restbetrag darf an die Kundin ausgekehrt werden.</li>
                  <li>Sind in einem Abrechnungsmonat keine abtretbaren Vergütungsansprüche gegen die opta data Finance GmbH entstanden, ist eine ggfs. noch bestehende Restforderung in einer Summe sofort zur Rückzahlung an die odITS fällig, sofern dem Kunden nicht ausdrücklich eine Stundung gewährt wird.</li>
                </ul>

              </div>
            </div>
          </v-card-text>

          <v-card-actions class="pa-4">

            <button @click="dialog = false" class="button-standard-continue d-flex align-center justify-center">
              <span class="poppins medium">Schließen</span>
            </button>

          </v-card-actions>

        </v-card>

      </v-dialog>


      <form id="userDataForm" class="p-4 prevent" @submit.prevent="submit()">


        <div class="pa-4">

          <h2 class="main-color-1"> Allgemeine Daten </h2>

          <div class="mt-4">
            <p class="b1 mb-2">Name des Unternehmens*:</p>
            <v-text-field v-model="formStore.submittedSteps.step6.unternehmen" type="text" maxlength="255" required
                          class="input-items" variant="outlined" density="compact" hide-details="auto"/>
          </div>

          <div class="mt-4">
            <p class="b1 mb-2">Adresse*:</p>
            <v-text-field v-model="formStore.submittedSteps.step6.adresse" type="text" max="255" required
                          class="input-items" variant="outlined" density="compact" hide-details="auto"/>
          </div>

          <div class="mt-4">
            <p class="b1 mb-2">Ansprechpartner*:</p>
            <v-text-field v-model="formStore.submittedSteps.step6.ansprechpartner" type="text" max="255" required
                          class="input-items" variant="outlined" density="compact" hide-details="auto"/>
          </div>

          <div class="mt-4">
            <p class="b1 mb-2">E-Mail (Rechnungsempfänger)*:</p>
            <v-text-field v-model="formStore.submittedSteps.step6.email" max="255" required type="email"
                          class="input-items" variant="outlined" density="compact" hide-details="auto"/>
          </div>

          <div class="mt-4">
            <div style="gap:30px;" class="d-flex">
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Telefon*:</p>
                <v-text-field v-model="formStore.submittedSteps.step6.telefon" type="tel" required class="input-items"
                              variant="outlined" density="compact" hide-details="auto"/>
              </div>
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Fax:</p>
                <v-text-field v-model="formStore.submittedSteps.step6.fax" class="input-items" variant="outlined"
                              density="compact" hide-details="auto"/>
              </div>
            </div>
          </div>


          <button type="submit"> submit</button>

          <div class="mt-4">

            <div class="d-flex">
              <p class="b1">IK:</p>
            </div>

            <div style="gap:30px;" class="d-flex">
              <div style="flex-basis: 70%" class="d-flex flex-column">
                <v-text-field v-model="formStore.submittedSteps.step6.ik" type="number" maxlength="9"
                              class="input-items" variant="outlined" density="compact" hide-details="auto"/>
              </div>
              <div style="flex-basis: 30%" class="d-flex flex-column w-100">
                <ButtonStandardUpload/>
              </div>
            </div>
          </div>

          <div class="mt-4">

            <div class="d-flex">
              <p class="b1">Verbandsmitglied:</p>
            </div>

            <div style="gap:30px;" class="d-flex">
              <div style="flex-basis: 70%" class="d-flex flex-column">
                <v-text-field v-model="formStore.submittedSteps.step6.verbandsmitglied" class="input-items"
                              variant="outlined" density="compact" hide-details="auto"/>

              </div>
              <div style="flex-basis: 30%" class="d-flex flex-column w-100">
                <ButtonStandardUpload/>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <h2 class="main-color-1"> Zahlungsdaten </h2>
          </div>

          <div class="mt-4">
            <v-radio-group
                v-model="formStore.submittedSteps.step6.zahlungsart"
                inline
            >
              <v-radio
                  label="SEPA"
                  value="sepa"
              ></v-radio>
              <v-radio
                  label="Abtretungserklärung"
                  value="abtretungserklaerung"
              ></v-radio>
              <div @click="dialog = true"><img style="filter: brightness(0) invert(0);"
                                               :src="require('@/assets/images/info.svg')" alt=""/></div>

            </v-radio-group>
          </div>

          <div v-if="formStore.submittedSteps.step6.zahlungsart === 'sepa'">

            <div class="mt-4">
              <p class="b1 mb-2">Kontoinhaber*:</p>
              <v-text-field v-model="formStore.submittedSteps.step6.sepa_kontoinhaber" type="text" max="255" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

            <div class="mt-4">
              <p class="b1 mb-2">Adresse*:</p>
              <v-text-field v-model="formStore.submittedSteps.step6.sepa_adresse" type="text" max="255" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

            <div class="mt-4">
              <p class="b1 mb-2">BIC*:</p>
              <v-text-field v-model="formStore.submittedSteps.step6.sepa_bic" type="text" min="11" max="11" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

            <div class="mt-4">
              <p class="b1 mb-2">IBAN*:</p>
              <v-text-field v-model="formStore.submittedSteps.step6.sepa_iban" type="text" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

          </div>

          <div v-if="formStore.submittedSteps.step6.zahlungsart === 'abtretungserklaerung'">
            <div class="mt-4">
              <p class="b1 mb-2">Kundennummer*:</p>
              <v-text-field v-model="formStore.submittedSteps.step6.kundennummer" type="number" :counter="25"
                            maxlength="25" minlength="25" required class="input-items" variant="outlined"
                            density="compact" hide-details="auto"/>
            </div>
          </div>


        </div>
      </form>

    </v-card-text>

    <v-card-actions class="actions-container">
      <ButtonStandardBack/>


      <button form="userDataForm" type="submit" class="button-standard-continue d-flex align-center justify-center">
        <span style="color:white" class="poppins medium mr-2">Weiter</span>
        <img
            :src="require('@/assets/images/arrow-forward.svg')" alt="">
      </button>

    </v-card-actions>

  </v-card>


</template>

<script>

import ButtonStandardBack from "@/components/items/ButtonStandardBack.vue";
import ButtonStandardUpload from "@/components/items/FormStep6/ButtonStandardUpload.vue";
import {useFormStore} from "@/stores/FormStore";

export default {

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

  components: {
    ButtonStandardUpload,
    ButtonStandardBack
  },

  methods: {
    submit() {
      this.formStore.currentStep++;
    },
  },

  data() {
    return {
      selected: 1,
      dialog: false,
    }
  },
}

</script>


<style scoped>

</style>
