<template>

  <button :disabled="true" class="h-100 button-standard-upload d-flex align-center justify-center">
    <img style="margin-right: 8px"
         :src="require('@/assets/images/upload.svg')" alt="">
    <span class="first-span">Datei Hochladen</span>
  </button>


</template>


<script>

export default {}

</script>
