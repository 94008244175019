<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">
      <form>
        <p>Vertriebsmitarbeiter:in</p>
        <v-text-field v-model="formStore.submittedSteps.step1.vertriebler" :readonly="isReadOnly" style="margin-bottom: 32px"/>

        <h3 style="margin-bottom: 16px" class="text-center main-color-1">Unternehmensform (hauptsächlich):</h3>

        <v-radio-group
            v-model="formStore.chosenTargetGroup">
          <v-radio class="b1 text-color" label="Ambulanter Pflegedienst" :value="1"/>
          <v-radio class="b1 text-color" label="Tages- / Nachtpflege" :value="2"/>
          <v-radio class="b1 text-color" label="Ambulanter Betreuungsdienst" :value="3"/>
          <v-radio class="b1 text-color" label="Intensivpflegedienst" :value="4"/>
        </v-radio-group>
      </form>
    </v-card-text>
    <v-card-actions class="actions-container">
      <ButtonStandardContinue/>
    </v-card-actions>
  </v-card>
</template>

<script>
import {useFormStore} from "@/stores/FormStore";
import ButtonStandardContinue from "@/components/items/ButtonStandardContinue.vue";

export default {
  components: {ButtonStandardContinue},
  setup() {
    const formStore = useFormStore();
    return {formStore}
  },

  computed:{
    isReadOnly(){
      if(this.formStore.salesId === '1'){
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
form {
  margin-top: 64px;
}
</style>
