<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">

      <ClientCostHeader/>

      <div class="d-flex flex-column w-100 pr-4 pl-4 mt-6">
        <div class="d-flex content-header-item-container justify-space-between">

          <h2 class="b1 main-color-1">E-Learning </h2>

          <div class="d-flex content-header-item-container text-center">
            <p class="main-color-1 content-header-item">Anzahl</p>
            <p class="main-color-1 content-header-item">Einzelpreis</p>
            <p class="main-color-1 content-header-item">Gesamtpreis</p>
          </div>
        </div>


        <div v-for="n in formStore.getModelCsvConfigByMask" :key="n">

          <AdvancedModuleContentItem :module-title="n.position" :description-header="n.Bezeichnung"
                                     :description-text="n.Beschreibung" :price="n['Preis einmalig']"/>
        </div>
      </div>

    </v-card-text>

    <v-card-actions class="actions-container">
      <ButtonStandardBack/>
      <ButtonStandardContinue/>
    </v-card-actions>

  </v-card>

</template>

<script>

import ButtonStandardBack from "@/components/items/ButtonStandardBack.vue";
import ButtonStandardContinue from "@/components/items/ButtonStandardContinue.vue";
import ClientCostHeader from "@/components/items/ClientCostHeader.vue";
import AdvancedModuleContentItem from "@/components/items/FormStep5/AdvancedModuleContentItem.vue";
import {useFormStore} from "@/stores/FormStore";

export default {
  components: {
    AdvancedModuleContentItem,
    ClientCostHeader, ButtonStandardContinue, ButtonStandardBack
  },

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },
}

</script>


<style scoped>


.content-header-item {
  flex-basis: 33.33%;
}

.content-header-item-container {
  flex-shrink: 0;
  flex-basis: 50%;
}

</style>
