export async function loadFonts () {
    // @ts-ignore
    const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

    webFontLoader.load({
        google: {
            api: 'https://fonts.googleapis.com/css2',
            families: ['Poppins:wght@400;500;600&display=swap'],
        },
    })
}
