<template>

  <div class="d-flex flex-row w-100 pa-4 pb-0">

    <div class="d-flex flex-column mr-auto"><p class="item-header-text">Anzahl der Klienten:</p>

      <v-text-field type="number"
                    min="0"
                    step="1"
                    onfocus="this.previousValue = this.value"
                    onkeydown="this.previousValue = this.value"
                    oninput="validity.valid || (value = this.previousValue)" v-model="formStore.numberOfClients"
                    :readonly="formStore.currentStep > 2" variant="outlined"
                    density="compact" hide-details="auto"></v-text-field>
    </div>

    <div class="d-flex flex-column mr-4 text-center">
      <p class="item-header-text">einmalig</p>
      <div class="ufo-big main-color-1"><p style="padding-top: 10px; padding-bottom: 10px;">{{ singleCosts }}</p></div>
    </div>

    <div class="d-flex flex-column text-center">
      <p class="item-header-text">monatlich</p>
      <div class="ufo-big main-color-1">

        <div class="d-flex flex-column"></div>
        <p>min.: {{ monthlyCosts.min }}</p>
        <p>aktl.: {{ getAktlCost }}</p>
      </div>
    </div>

  </div>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {

  setup() {
    const formStore = useFormStore();
    return {formStore}
  },

  methods: {
    findElementByPosition(position) {
      return this.formStore.priceModelCsvConfig.filter((el) => {
        return el.position.toString() === position.toString();
      });
    }
  },

  computed: {

    getAktlCost() {

      console.log(this.formStore.clientCosts.minNumber, this.formStore.clientCosts.aktNumber, this.formStore.clientCosts.min)

      if (Number(this.formStore.clientCosts.minNumber) >= Number(this.formStore.clientCosts.aktNumber)) {
        return this.formStore.clientCosts.min;
      } else {
        return this.formStore.clientCosts.akt;
      }
    },


    monthlyCosts() {

      let current = 0;
      let min = 0;

      for (const [key, value] of Object.entries(this.formStore.submittedSteps)) {

        if (key === 'step2' || key === 'step3' || key === 'step4')

          if (value && key) {
            for (const [k, v] of Object.entries(value)) {

              if (v.length > 0 && k !== null) {

                for (let i = 0; i < v.length; i++) {


                  if (k === "selected") {
                    current += this.findElementByPosition(v[i])[0]['Preis je Klient'] * this.formStore.numberOfClients;

                    if (key === 'step3') {
                      min += this.findElementByPosition(v[i])[0]['Preis je Klient'] * this.formStore.numberOfClients;
                    }

                  } else if (k === "selectedIntense") {
                    current += this.findElementByPosition(v[i])[0]['Preis je Klient'] * this.formStore.numberOfIntenseClients;

                    if (key === 'step3') {
                      min += this.findElementByPosition(v[i])[0]['Preis je Klient'] * this.formStore.numberOfIntenseClients;
                    }
                  }

                  if (key === 'step4') {
                    console.log("step4", k, v)

                    const element = this.findElementByPosition(k)[0];


                    console.log("entity", element);

                    min += element['Preis je Klient'] * v;
                    current += element['Preis je Klient'] * v;


                  }

                  if (key !== 'step4') {
                    console.log('position', this.findElementByPosition(v[i])[0]['position'].trim());
                    if (this.findElementByPosition(v[i])[0]['mindestanzahl'].trim()) {


                      min += this.findElementByPosition(v[i])[0]['Preis je Klient'] * this.findElementByPosition(v)[0]['mindestanzahl']
                    }
                  }
                }
              }
            }
          }
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formStore.clientCosts.minNumber = min.toFixed(2);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formStore.clientCosts.aktNumber = current.toFixed(2);

      current = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(current.toFixed(2));
      min = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(min.toFixed(2));

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formStore.clientCosts.min = min;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formStore.clientCosts.akt = current;

      return {current, min}
    },

    singleCosts() {
      let total = 0;

      for (const [key, value] of Object.entries(this.formStore.submittedSteps)) {

        if (key === 'step3' || key === 'step4' || key === 'step5') {


          if (value && key) {
            for (const [k, v] of Object.entries(value)) {


              if (k === 'selectedSingleOrder' || k === 'selectedIntenseSingleOrder') {

                console.log(k, v)

                for (let i = 0; i < v.length; i++) {
                  if (this.findElementByPosition(v[i])[0]) {
                    total += this.findElementByPosition(v[i])[0]['Preis einmalig'];
                  }
                }
              }


              if (this.findElementByPosition(k)[0]) {
                total += (Number(v) * this.findElementByPosition(k)[0]['Preis einmalig']);
              }
            }
          }
        }
      }

      total = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(total.toFixed(2))

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formStore.clientCosts.single = total;

      return total;
    }
  }
}

</script>

<style scoped>

.item-header-text {
  margin-bottom: 8px;
}

</style>
