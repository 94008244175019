<template>

  <button @click="formStore.currentStep--" class="button-standard-back d-flex align-center justify-center">
    <img
        :src="require('@/assets/images/arrow-back.svg')" alt="">
    <span class="first-span">Zurück</span>
  </button>


</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {

  setup() {
    const formStore = useFormStore();

    return {formStore}
  }

}

</script>
