<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column">

      <form class="p-4" @submit.prevent="submit">
        <div class="pa-4">

          <h2 class="main-color-1"> Zusammenfassung </h2>

          <div class="d-flex flex-row w-100 pa-4 pl-0">
            <div class="d-flex flex-column mr-6"><p class="step-3-item-header-text">Anzahl der Klienten:</p>

              <v-text-field :disabled="true" :model-value="formStore.numberOfClients" variant="outlined"
                            density="compact"
                            hide-details="auto"></v-text-field>
            </div>
            <div class="d-flex flex-column mr-auto"><p class="step-3-item-header-text">Anzahl der Klienten
              (intensiv):</p>

              <v-text-field :disabled="true" :model-value="formStore.numberOfIntenseClients" variant="outlined"
                            density="compact"
                            hide-details="auto"></v-text-field>
            </div>
          </div>

          <div class="d-flex flex-column w-100">
            <div class="d-flex justify-space-between">
              <h2 class="main-color-1 mt-4"> Servicepaket </h2>
              <p class="main-color-1">je Klient</p>
            </div>

            <div v-for="n in findElementByPosition(formStore.submittedSteps.step2.selected[0])" :key="n">

              <ModuleContentItem :check-box-label="n.position" :description-header="n.Bezeichnung"
                                 :description-text="n.Beschreibung"
                                 :price="convertToGermanCurrency(n['Preis je Klient'])"/>
            </div>
          </div>

          <div v-if="getSelectedModules.length > 0">

            <div class="d-flex justify-space-between">
              <h2 class="main-color-1 mt-4"> Module </h2>
              <p class="main-color-1">je Klient</p>
            </div>

            <div v-for="n in getSelectedModules" :key="n">

              <ModuleContentItem :check-box-label="n.position" :description-header="n.Bezeichnung"
                                 :description-text="n.Beschreibung"
                                 :price="convertToGermanCurrency(n['Preis je Klient'])"/>
            </div>

          </div>

          <div v-if="getSelectedModulesSingleOrder.length > 0">

            <div class="d-flex justify-space-between">
              <h2 class="main-color-1 mt-4"> Module </h2>
              <p class="main-color-1">einmalig</p>
            </div>

            <div v-for="n in getSelectedModulesSingleOrder" :key="n">

              <ModuleContentItem :check-box-label="n.position" :description-header="n.Bezeichnung"
                                 :description-text="n.Beschreibung"
                                 :price="convertToGermanCurrency(n['Preis einmalig'])"/>
            </div>

          </div>

          <div v-if="getSelectedExtraServices.length > 0">

            <div class="d-flex flex-column w-100 mt-4">
              <div class="d-flex justify-space-between">
                <h2 class="main-color-1 mt-4"> Monatliche Zusatzleistungen </h2>
                <p class="main-color-1">je Klient</p>
              </div>

              <div v-for="n in getSelectedExtraServices" :key="n">

                <div v-if="n != null">


                  <ModuleContentItem :check-box-label="n.position" :description-header="n.Bezeichnung"
                                     :description-text="n.Beschreibung"
                                     :price="convertToGermanCurrency(n['Preis je Klient'])"/>

                </div>
              </div>
            </div>

          </div>

          <div v-if="getSelectedELearning.length > 0">

            <div class="d-flex flex-column w-100 mt-4">
              <div class="d-flex content-header-item-container justify-space-between">

                <h2 class="main-color-1"> E-Learning </h2>

                <div class="d-flex content-header-item-container text-center">
                  <p class="main-color-1 content-header-item">Anzahl</p>
                  <p class="main-color-1 content-header-item">Einzelpreis</p>
                  <p class="main-color-1 content-header-item">Gesamtpreis</p>
                </div>
              </div>

              <div v-for="n in getSelectedELearning" :key="n">

                <div v-if="n != null">
                  <AdvancedModuleContentItemELearning :module-title="n.position" :description-header="n.Bezeichnung"
                                                      :description-text="n.Beschreibung" :price="n['Preis einmalig']"/>
                </div>
              </div>
            </div>

          </div>

          <v-divider thickness="2" style="color: #005d75" class="ma-4 ml-0 mr-0 align-self-center"></v-divider>

          <h2 class="main-color-1 mt-4"> Gesamtkosten </h2>

          <div class="d-flex justify-center align-center">

            <div class="d-flex flex-column mr-4 text-center">
              <p class="item-header-text mb-2">einmaling</p>
              <div class="ufo-big main-color-1"><p style="padding-top: 10px; padding-bottom: 10px;">
                {{ formStore.clientCosts.single }}</p></div>
            </div>

            <div class="d-flex flex-column text-center">
              <p class="item-header-text mb-2">monatlich</p>
              <div class="ufo-big main-color-1">

                <div class="d-flex flex-column"></div>
                <p>min.: {{ formStore.clientCosts.min }}</p>
                <p>aktl.: {{ formStore.clientCosts.akt }}</p>
              </div>
            </div>
          </div>

          <v-checkbox class="mt-15" v-model="formStore.submittedSteps.step7.selected" value="agb" density="compact"
                      hide-details="auto"
               >



            <template v-slot:label>
              <div>Ich stimme den <a target="_blank" :href="getAGBLocation()">allgemeinen Geschäftsbedingungen</a> zu.*</div>

            </template>

          </v-checkbox>

          <v-checkbox v-model="formStore.submittedSteps.step7.selected" value="datenschutz" density="compact"
                      hide-details="auto"
>
            <template v-slot:label>
              <div>Ich stimme den <a target="_blank" :href="getDSGVOLocation()">Datenschutzbestimmungen</a> zu.*</div>

            </template>


          </v-checkbox>

          <h2 class="main-color-1 mt-15"> Digitale Unterschrift </h2>

          <div class="mt-4 signature-container">

            <Vue3Signature ref="signature1" :sigOption="state.option" :w="'100%'" :h="'250px'"
                           :disabled="state.disabled" class="example"></Vue3Signature>

          </div>
          <button class="button-standard-continue mt-3" @click="clear">Unterschrift neu</button>
        </div>
      </form>

    </v-card-text>

    <v-card-actions class="actions-container">

      <div class="d-flex flex-column align-center">
        <button @click="finishForm()" class="button-standard-continue d-flex align-center justify-center">
          <span class="poppins medium">Jetzt kostenpflichtig bestellen</span>
          <img class="ml-2"
               :src="require('@/assets/images/payment.svg')" alt="">
        </button>

        <button @click="formStore.currentStep--"
                class="mt-8 w-50 button-standard-back d-flex align-center justify-center">
          <span>Abbrechen</span>
        </button>

      </div>

    </v-card-actions>

  </v-card>


</template>

<script>

import {reactive, ref} from "vue";
import {useFormStore} from "@/stores/FormStore";
import ModuleContentItem from "@/components/items/FormStep7/ModuleContentItem.vue";
import AdvancedModuleContentItemELearning from "@/components/items/FormStep7/AdvancedModuleContentItemELearning.vue";

export default {
  components: {
    AdvancedModuleContentItemELearning,
    ModuleContentItem,
  },

  setup() {

    const formStore = useFormStore();

    const state = reactive({
      count: 0,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      disabled: false
    })

    const signature1 = ref(null)

    const save = (t) => {
      return signature1.value.save(t);
    }

    const clear = () => {
      signature1.value.clear()
    }

    return {state, signature1, save, clear, formStore};

  },

  methods: {
    findElementByPosition(position) {
      return this.formStore.priceModelCsvConfig.filter((el) => {
        return el.position.toString() === position.toString();
      });
    },
    convertToGermanCurrency(price) {
      return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
    },

    getAGBLocation(){
      return this.getWindowLocation() + 'files/Anlage-A_AGB_Pflege.pdf';
    },

    getDSGVOLocation(){
      return this.getWindowLocation() + 'files/Anlage-D_Transparenzgebote_odITS.pdf';
    },

    getWindowLocation(){
      return window.location.href.split('?')[0];
    },

    async finishForm() {

      if(this.signature1.isEmpty()){
        return;
      }

      if(this.formStore.submittedSteps.step7.selected.length !== 2){
        return;
      }

      const signatureString = this.save();

      let finishPositionsArrayofObj = [];

      for (const [key, value] of Object.entries(this.formStore.submittedSteps)) {
        if (key !== null && value !== null) {
          for (const [k, v] of Object.entries(value)) {

            if (k !== null && v !== null) {

              if(key === 'step3'){
                console.log(value);

                console.log("k, v", k, v)
              }

              //Arrays
              if (key === 'step2' || key === 'step3') {
                for (let i = 0; i < v.length; i++) {

                  let tempObj = {};

                  const element = this.findElementByPosition(v[i])[0];
                  tempObj.Positionsnummer = element.position;
                  tempObj.Bezeichnung = element.Bezeichnung;

                  if (k.includes('Intense')) {
                    tempObj.Anzahl = this.formStore.numberOfIntenseClients;
                    tempObj.EinzelpreisEinmalig = this.convertToGermanCurrency(element['Preis einmalig']);
                    tempObj.EinzelpreisMonatlich = this.convertToGermanCurrency(element['Preis je Klient']);
                  } else {
                    tempObj.Anzahl = this.formStore.numberOfClients;
                    tempObj.EinzelpreisEinmalig = this.convertToGermanCurrency(element['Preis einmalig']);
                    tempObj.EinzelpreisMonatlich = this.convertToGermanCurrency(element['Preis je Klient']);
                  }

                  if(key === 'step2'){
                    tempObj.Mask = this.formStore.stepMaskMapping[2];
                  } else if(key === 'step3'){
                    tempObj.Mask = this.formStore.stepMaskMapping[3];
                  }

                  tempObj.Bezeichnung = element.Bezeichnung;

                  finishPositionsArrayofObj.push(tempObj);
                }
              }
            }
          }

          //Objects
          if (key === 'step4' || key === 'step5') {
            for (const [k, v] of Object.entries(value)) {

              if (k !== null && v !== null && v !== "") {
                let tempObj = {};

                const element = this.findElementByPosition(k)[0];
                tempObj.Positionsnummer = element.position;
                tempObj.Bezeichnung = element.Bezeichnung;
                tempObj.Anzahl = v;
                tempObj.EinzelpreisEinmalig = this.convertToGermanCurrency(element['Preis einmalig']);
                tempObj.EinzelpreisMonatlich = this.convertToGermanCurrency(element['Preis je Klient']);

                if(key === 'step4'){
                  tempObj.Mask = this.formStore.stepMaskMapping[4];
                } else if(key === 'step5'){
                  tempObj.Mask = this.formStore.stepMaskMapping[5];
                }

                finishPositionsArrayofObj.push(tempObj);
              }
            }
          }

        }
      }

      console.log(finishPositionsArrayofObj);

      let paymentMethodObj = {};

      if (this.formStore.submittedSteps.step6.zahlungsart === 'sepa') {
        paymentMethodObj = {
          bezeichnung: this.formStore.submittedSteps.step6.zahlungsart, werte: {
            kontoinhaber: this.formStore.submittedSteps.step6.sepa_kontoinhaber,
            adresse: this.formStore.submittedSteps.step6.sepa_adresse,
            bic: this.formStore.submittedSteps.step6.sepa_bic,
            iban: this.formStore.submittedSteps.step6.sepa_iban,
          }
        }
      } else {
        paymentMethodObj = {
          bezeichnung: this.formStore.submittedSteps.step6.zahlungsart, werte: {
            kundennummer: this.formStore.submittedSteps.step6.kundennummer,
          }
        }
      }

      const finishFormObj = this.formStore.generateFinishFormObj(finishPositionsArrayofObj, paymentMethodObj);
      console.log("FinishFormObj", finishFormObj);

      console.log("signature", signatureString)

      //await this.formStore.generatePDF(finishFormObj, signatureString);

      this.formStore.saveOrder(finishFormObj).then((data) => {
        if(data.status === 200){
          alert("Kauf erfolgreich abgeschlossen");
        }
      });
    }
  },

  data() {
    return {
      selected: 1,
    }
  },

  computed: {
    getSelectedModules() {

      let moduleArray = [];

      for (let i = 0; i < this.formStore.submittedSteps.step3.selected.length; i++) {
        console.log(this.formStore.submittedSteps.step3.selected[i])
        moduleArray.push(this.findElementByPosition(this.formStore.submittedSteps.step3.selected[i])[0]);
      }

      if (this.formStore.numberOfIntenseClients > 0) {
        for (let i = 0; i < this.formStore.submittedSteps.step3.selectedIntense.length; i++) {
          console.log(this.formStore.submittedSteps.step3.selectedIntense[i])
          moduleArray.push(this.findElementByPosition(this.formStore.submittedSteps.step3.selectedIntense[i])[0]);
        }
      }

      return moduleArray
    },

    getSelectedModulesSingleOrder() {

      let moduleArray = [];

      for (let i = 0; i < this.formStore.submittedSteps.step3.selectedSingleOrder.length; i++) {
        console.log(this.formStore.submittedSteps.step3.selectedSingleOrder[i])
        moduleArray.push(this.findElementByPosition(this.formStore.submittedSteps.step3.selectedSingleOrder[i])[0]);
      }

      if (this.formStore.numberOfIntenseClients > 0) {
        for (let i = 0; i < this.formStore.submittedSteps.step3.selectedIntenseSingleOrder.length; i++) {
          console.log(this.formStore.submittedSteps.step3.selectedIntenseSingleOrder[i])
          moduleArray.push(this.findElementByPosition(this.formStore.submittedSteps.step3.selectedIntenseSingleOrder[i])[0]);
        }
      }

      return moduleArray
    },

    getSelectedExtraServices() {

      let extraServicesArray = [];

      for (const [key, value] of Object.entries(this.formStore.submittedSteps.step4)) {
        if (value !== "" && value !== null && value) {
          extraServicesArray.push(this.findElementByPosition(key)[0]);
        }
      }
      return extraServicesArray;
    },
    getSelectedELearning() {
      let extraServicesArray = [];

      for (const [key, value] of Object.entries(this.formStore.submittedSteps.step5)) {
        if (value !== "" && value !== null && value) {
          extraServicesArray.push(this.findElementByPosition(key)[0]);
        }
      }
      return extraServicesArray;
    }
  }
}

</script>


<style scoped>
.example {
  margin: 0 auto;
}

.signature-container {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.content-header-item {
  flex-basis: 33.33%;
  text-align: end;
}

.content-header-item-container {
  flex-shrink: 0;
  flex-basis: 50%;
}


</style>
