<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">

      <ClientCostHeader/>

      <div class="d-flex flex-column w-100 pr-4 pl-4 mt-6">
        <div class="d-flex justify-space-between">
          <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
          <p class="main-color-1">je Klient</p>
        </div>

        <div v-for="n in clientsNotIntensive" :key="n">

          <ModuleContentItem :check-box-label="n.position" :description-header="n.Bezeichnung"
                             :description-text="n.Beschreibung" :price="convertToGermanCurrency(n['Preis je Klient'])"/>
        </div>

        <div v-if="clientsNotIntensiveSingleOrder.length > 0">

          <div class="d-flex justify-space-between mt-4 mb-4">
            <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
            <p class="main-color-1">einmalig</p>
          </div>


          <div v-for="n in clientsNotIntensiveSingleOrder" :key="n">

            <ModuleContentItemSingleOrder :check-box-label="n.position" :description-header="n.Bezeichnung"
                                          :description-text="n.Beschreibung"
                                          :price="convertToGermanCurrency(n['Preis einmalig'])"/>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row w-100 pa-4 pb-0">

        <div class="d-flex flex-column mr-auto"><p class="step-3-item-header-text">Anzahl der Klienten (intensiv):</p>

          <v-text-field type="number"
                        min="0"
                        step="1"
                        onfocus="this.previousValue = this.value"
                        onkeydown="this.previousValue = this.value"
                        oninput="validity.valid || (value = this.previousValue)"
                        v-model="formStore.numberOfIntenseClients" variant="outlined" density="compact"
                        hide-details="auto"></v-text-field>
        </div>
      </div>


      <div class="d-flex flex-column w-100 pr-4 pl-4 mt-6">
        <div class="d-flex justify-space-between">
          <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
          <p class="main-color-1">je Klient</p>
        </div>


        <div v-for="n in clientsIntensive" :key="n">

          <ModuleContentItemIntense :check-box-label="n.position" :description-header="n.Bezeichnung"
                                    :description-text="n.Beschreibung"
                                    :price="convertToGermanCurrency(n['Preis je Klient'])"/>
        </div>


        <div v-if="clientsIntensiveSingleOrder.length > 0">

          <div class="d-flex justify-space-between mt-4 mb-4">
            <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
            <p class="main-color-1">einmalig</p>
          </div>

          <div v-for="n in clientsIntensiveSingleOrder" :key="n">

            <ModuleContentItemIntenseSingleOrder :check-box-label="n.position" :description-header="n.Bezeichnung"
                                                 :description-text="n.Beschreibung"
                                                 :price="convertToGermanCurrency(n['Preis einmalig'])"/>
          </div>
        </div>

      </div>

    </v-card-text>

    <v-card-actions class="actions-container">
      <ButtonStandardBack/>
      <ButtonStandardContinue :prevent-continue="hasValidationFailed()"/>
    </v-card-actions>
  </v-card>


</template>

<script>

import ButtonStandardBack from "@/components/items/ButtonStandardBack.vue";
import ButtonStandardContinue from "@/components/items/ButtonStandardContinue.vue";
import ModuleContentItem from "@/components/items/FormStep3/ModuleContentItem.vue";
import ClientCostHeader from "@/components/items/ClientCostHeader.vue";
import {useFormStore} from "@/stores/FormStore";
import ModuleContentItemIntense from "@/components/items/FormStep3/ModuleContentItemIntense.vue";
import ModuleContentItemSingleOrder from "@/components/items/FormStep3/ModuleContentItemSingleOrder.vue";
import ModuleContentItemIntenseSingleOrder from "@/components/items/FormStep3/ModuleContentItemIntenseSingleOrder.vue";

export default {
  components: {
    ModuleContentItemIntenseSingleOrder,
    ModuleContentItemSingleOrder,
    ModuleContentItemIntense, ClientCostHeader, ModuleContentItem, ButtonStandardContinue, ButtonStandardBack
  },

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

  methods: {
    convertToGermanCurrency(price) {
      return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
    },
    hasValidationFailed() {
      if (this.formStore.submittedSteps.step3.selectedIntense.length > 0) {
        if (this.formStore.numberOfIntenseClients > 0) {
          return false;
        } else {
          return true;
        }
      }
    },
  },

  computed: {
    clientsIntensive() {
      return this.formStore.getModelCsvConfigByMask.filter((el) => {
        return Number(el['Klienten(intensiv)']) === 1;
      }).filter((el) => {
        return Number(el['Preis einmalig'] === 0)
      })
    },
    clientsIntensiveSingleOrder() {
      return this.formStore.getModelCsvConfigByMask.filter((el) => {
        return Number(el['Klienten(intensiv)']) === 1;
      }).filter((el) => {
        return Number(el['Preis einmalig'] !== 0)
      })
    },
    clientsNotIntensive() {
      return this.formStore.getModelCsvConfigByMask.filter((el) => {
        return Number(el['Klienten(intensiv)']) !== 1;
      }).filter((el) => {
        return Number(el['Preis einmalig'] === 0)
      })
    },
    clientsNotIntensiveSingleOrder() {
      return this.formStore.getModelCsvConfigByMask.filter((el) => {
        return Number(el['Klienten(intensiv)']) !== 1;
      }).filter((el) => {
        return Number(el['Preis einmalig'] !== 0)
      })
    }
  }
}

</script>


<style scoped>


</style>
