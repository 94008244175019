<template>

  <div class="d-flex pt-4 pb-4">
    <div style="flex-basis: 70px">
      <p class="positionTitle">{{ checkBoxLabel }}</p>
    </div>
    <div class="d-flex flex-column descriptionContainer mr-auto">
      <h2 class="mb-2">{{ descriptionHeader }}</h2>
      <p>{{ descriptionText }}</p>
    </div>

    <h3>{{ price }}</h3>
  </div>
</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {
  props: ['checkBoxLabel', 'descriptionHeader', 'descriptionText', 'price'],

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },
}


</script>


<style scoped>

.descriptionContainer {
  padding-top: 2px;
}

.positionTitle {
  font-weight: 300;
  margin-top: 2px;
  font-size: 1.1rem;
}

</style>
