<template>

  <div class="service-content" :style="{'background': backgroundColor}">

    <div v-if="columnContent && columnContent.position"
         :class="{'recommended-item': recommended, 'ufo-small': recommended}"
         class="w-100 h-100 d-flex flex-row justify-space-between align-center">
      <v-checkbox @click="updateCheckbox()" v-model="formStore.submittedSteps.step2.selected" density="compact"
                  hide-details="auto"
                  :value="columnContent.position" :label="columnContent.position"></v-checkbox>
      <h3>{{ price }}</h3>
    </div>

    <div class="recommended-text-container" v-if="recommended">
      <img
          :src="require('@/assets/images/arrow-recommandation.svg')" alt="">
      <span class="recommend-text poppins medium main-color-1">Empfohlen</span>
    </div>

  </div>


</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {storeToRefs} from "pinia";

export default {
  props: ['backgroundColor', 'columnContent'],

  setup() {
    const formStore = useFormStore();

    const submittedSteps = storeToRefs(formStore)

    return {formStore, submittedSteps}
  },

  methods: {
    updateCheckbox() {

      const myFormStoreArray = this.formStore.submittedSteps.step2.selected;

      this.formStore.submittedSteps.step2.selected = myFormStoreArray.slice(myFormStoreArray.length + 1 - 1, 1)

      console.log("length of my array", myFormStoreArray.length);
    }
  },

  computed: {
    recommended() {
      if (this.columnContent?.empfohlen) {
        return Number(this.columnContent.empfohlen) === 1;
      } else {
        return false;
      }
    },
    price() {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.columnContent['Preis je Klient'])
    }
  }
}

</script>

<style>

.recommended-text-container {
  position: absolute;
  bottom: -3px;
}

.service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 16px;
  flex-basis: 25%;
}

.service-content .v-label {
  margin-left: 4px;
}

.recommend-text {
  margin: 8px 4px 4px;
}

.recommended-item {
  background: #2E6F82;
}

.recommended-item h3 {
  color: white;
}

.recommended-item .v-icon {
  color: white;
  opacity: unset;
}


.recommended-item .v-label {
  color: white;
}


</style>
