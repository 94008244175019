import {defineStore} from "pinia";
import axios from "axios"
// @ts-ignore
import papa from "papaparse";
// @ts-ignore
import currencyFormatter from "currency-formatter";

export const useFormStore = defineStore('FormStore', {


    state: () => {

        const targetGroupMapping: any = {
            1: 'Ambulant', 2: 'Tagespflege', 3: 'Betreuung', 4: 'Ambulant',
        }

        const stepMaskMapping: any = {
            2: 'Service', 3: 'Module', 4: 'Zusatzleistungen', 5: 'E-Learning',
        }

        const clientCosts: any = {min: "", akt: "", single: "", aktNumber: 0, minNumber: 0}

        const quoteId: any = null;
        const salesId: any = null;

        const currentStep: number = 0;
        const totalSteps: number = 7;
        const priceModelCsvConfig: any[] = []

        const chosenTargetGroup = 1;
        const numberOfClients = 0;
        const numberOfIntenseClients = 0;

        const submittedSteps: { step1: any, step2: any, step3: any, step4: any, step5: any, step6: any, step7: any } = {
            step1: {vertriebler: ''},
            step2: {selected: []},
            step3: {selected: [], selectedSingleOrder: [], selectedIntense: [], selectedIntenseSingleOrder: []},
            step4: {},
            step5: {},
            step6: {
                zahlungsart: 'sepa',
                name: '',
                adresse: '',
                ansprechpartner: '',
                email: '',
                telefon: '',
                fax: '',
                ik: '',
                verbandsmitglied: '',

            },
            step7: {selected: []}
        };

        return {
            clientCosts,
            numberOfIntenseClients,
            quoteId,
            salesId,
            chosenTargetGroup,
            numberOfClients,
            targetGroupMapping,
            stepMaskMapping,
            priceModelCsvConfig,
            submittedSteps,
            currentStep,
            totalSteps,
        }
    },

    actions: {

        getPriceTypePerPosition(position: string) {

            const csvIndex = this.priceModelCsvConfig.map(e => e.position).indexOf(position);

            const priceModelObject = this.priceModelCsvConfig[csvIndex];

            let priceType = ''

            if (priceModelObject['Preis einmalig'] !== 0 && priceModelObject['Preis je Klient'] !== 0) {
                priceType = 'klient';
            } else if (priceModelObject['Preis einmalig'] !== 0) {
                priceType = 'einmalig';
            } else if (priceModelObject['Preis je Klient'] !== 0) {
                priceType = 'klient';
            }
        },

        getCorrectPricePerPosition(position: string){

            console.log("position", position);

            const csvIndex = this.priceModelCsvConfig.map(e => e.position).indexOf(position);

            const priceModelObject = this.priceModelCsvConfig[csvIndex];

            let price = 0;

            if(priceModelObject['Preis einmalig'] !== 0 && priceModelObject['Preis je Klient'] !== 0){
                price = priceModelObject['Preis je Klient'];
            } else if(priceModelObject['Preis einmalig'] !== 0){
                price = priceModelObject['Preis einmalig'];
            } else if(priceModelObject['Preis je Klient'] !== 0){
                price = priceModelObject['Preis je Klient'];
            }
            return price;
        },

        async generatePDF(body: {signature: any}, signatureString: string) {

            body.signature = signatureString;

            const data = await axios.post("https://opta.jg-agency.de/opta/generate2.php",
               body
            );

            if (data.status != 200) {
                throw new Error(data.statusText)
            }

            return data;
        },

        async saveOrder(body: {}){
            const data = await axios.post('https://onboarding.8zi.de/add', body, {
                auth: {
                    username: 'admin',
                    password: '82x8ETvmtSFFnxDH6hbT4q'
                }
            });

            if (data.status != 200) {
                throw new Error(data.statusText)
            }

            return data;
        },

        generateFinishFormObj(finishedPosArryofObj: [], paymentMethodObj: {}) {

            let costMonthly = '';

            if(this.clientCosts.akt >= this.clientCosts.min){
                costMonthly = this.clientCosts.akt;
            } else {
                costMonthly = this.clientCosts.min;
            }

            return {
                'Vertriebler':
                    {salesForceId: this.salesId,
                    name: this.submittedSteps.step1.vertriebler},
                'Kunden': {
                    salesForceId: this.quoteId,
                    name: this.submittedSteps.step6.unternehmen,
                    adresse: this.submittedSteps.step6.adresse,
                    ansprechpartner: this.submittedSteps.step6.ansprechpartner,
                    email: this.submittedSteps.step6.email,
                    telefon: this.submittedSteps.step6.telefon,
                    fax: this.submittedSteps.step6.fax,
                    ik: this.submittedSteps.step6.ik,
                    verbandsmitglied: this.submittedSteps.step6.verbandsmitglied,

                }, 'Vertrag': {
                    abschlussDatum: new Date().toLocaleString(),
                    gueltigAb: new Date().toLocaleString(),
                    zahlungsmethode: paymentMethodObj,
                    totalKostenEinmalig: this.clientCosts.single,
                    totalKostenMonatlich: costMonthly,

                    positionen: finishedPosArryofObj
                },
            };
        },

        getCsvConfigIndexByPosition(position: string){
            return this.priceModelCsvConfig.map(e => e.position).indexOf(position);
        },

        async fetchPriceModelCsv() {

            console.log("fetching");

            papa.parse('https://opta-csv.jg-agency.de/Preimodell.csv', {
                download: true, skipEmptyLines: true, header: true, complete: function (results: any) {
                    const formStore = useFormStore();

                    formStore.priceModelCsvConfig = results.data;

                    for (let i = 0; i < formStore.priceModelCsvConfig.length; i++) {
                        formStore.priceModelCsvConfig[i]['Preis einmalig'] = currencyFormatter.unformat(formStore.priceModelCsvConfig[i]['Preis einmalig'], {code: 'EUR'});
                        formStore.priceModelCsvConfig[i]['Preis je Klient'] = currencyFormatter.unformat(formStore.priceModelCsvConfig[i]['Preis je Klient'], {code: 'EUR'});

                        if (formStore.priceModelCsvConfig[i].Maske === 'E-Learning') {
                            if (!formStore.submittedSteps.step5[formStore.priceModelCsvConfig[i].position]) {
                                formStore.submittedSteps.step5[formStore.priceModelCsvConfig[i].position] = "";
                            }
                        }

                        if (formStore.priceModelCsvConfig[i].Maske === 'Zusatzleistungen') {
                            if (!formStore.submittedSteps.step4[formStore.priceModelCsvConfig[i].position]) {
                                formStore.submittedSteps.step4[formStore.priceModelCsvConfig[i].position] = "";
                            }
                        }

                        if (formStore.priceModelCsvConfig[i]['Preis einmalig'] === 0) {
                            formStore.priceModelCsvConfig[i]['originalpreis'] = true;
                        }
                        if (formStore.priceModelCsvConfig[i]['Preis je Klient'] === 0) {
                            formStore.priceModelCsvConfig[i]['originalpreis'] = true;
                        }
                    }

                    console.log(formStore.priceModelCsvConfig);
                }, error: function (err: any) {
                    throw(err);
                },
            })
        },
    },

    getters: {
        getModelCsvConfigByMask(): any[] {

            const result = this.priceModelCsvConfig.filter((el: any) => {
                return el.Maske.trim() === this.stepMaskMapping[this.currentStep];
            }).sort((a: any, b: any) => {
                return a.Reihenfolge - b.Reihenfolge;
            });

            console.log("alle elemente der Zielgruppe ambulant der maske service", result);

            return result;
        },
    },

    persist: {
        storage: sessionStorage
    }
});
