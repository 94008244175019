import {defineStore} from "pinia";

export const useApplicationStore = defineStore('ApplicationStore', {

    state: () => {

        const step: number = 1;

        return {
            step,
        }
    },

    actions: {},

    getters: {},

});
