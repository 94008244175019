<template>

  <div class="d-flex pt-4 pb-4">
    <div class="descriptionHeaderContainer d-flex">
      <div class="d-flex checkbox-container">
        <p class="b1">{{ moduleTitle }}</p>
      </div>
      <div class="d-flex flex-column pl-4 descriptionContainer mr-auto">
        <h2 class="mb-2">{{ descriptionHeader }}</h2>
        <p class="pr-4 h-100">{{ descriptionText }}</p>
      </div>
    </div>

    <div class="d-flex inputContainer align-start">
      <div class="d-flex justify-space-evenly text-center w-100">
        <v-text-field type="number"
                      min="0"
                      step="1"
                      onfocus="this.previousValue = this.value"
                      onkeydown="this.previousValue = this.value"
                      oninput="validity.valid || (value = this.previousValue)"
                      v-model="formStore.submittedSteps.step4[moduleTitle]" class="input-items" variant="outlined"
                      density="compact" hide-details="auto"></v-text-field>


        <h3 class="input-items align-self-center">{{ convertToGermanCurrency(price)}}</h3>

        <v-text-field :model-value="calculateAndFormatTotalPrice(formStore.submittedSteps.step4[moduleTitle], price)"
                      :readonly="true" class="input-items" variant="outlined" density="compact"
                      hide-details="auto"></v-text-field>
      </div>
    </div>

  </div>
</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {
  props: ['descriptionHeader', 'descriptionText', 'price', 'moduleTitle'],

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

  methods: {
    textEntered(test) {
      console.log(test)
    },
    calculateAndFormatTotalPrice(multiplier, price) {
      return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(multiplier * price)
    },
    convertToGermanCurrency(price) {
      return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
    },
    hasOriginalPrice(position){
      return this.formStore.priceModelCsvConfig[this.formStore.getCsvConfigIndexByPosition(position)]['originalpreis']
    }
  },
}


</script>


<style scoped>

.descriptionHeaderContainer {
  flex-basis: 50%;
}

.inputContainer {
  flex-basis: 50%;
}

.checkbox-container {
  flex-shrink: 0;
}

.input-items {
  flex-basis: 33%;
  align-self: start;
}

</style>
