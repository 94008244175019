<template>
  <v-app>
    <v-app-bar>
      <PageHeader/>
    </v-app-bar>

    <notifications />

    <v-main>
      <HomeContent v-if="formStore.currentStep === 0"/>
      <v-card v-if="formStore.currentStep >0" class="container-card">
        <v-card-title class="container-card-title">
          <ProgressBar :total-steps="formStore.totalSteps" :current-step="formStore.currentStep"/>
        </v-card-title>
        <v-card-text class="container-card-text">
            <div v-if="formStore.currentStep === 1">
              <FormStep1/>
            </div>
            <div v-if="formStore.currentStep === 2">
              <FormStep2/>
            </div>
          <div v-if="formStore.currentStep === 3">
            <FormStep3/>
          </div>
          <div v-if="formStore.currentStep === 4">
            <FormStep4/>
          </div>
          <div v-if="formStore.currentStep === 5">
            <FormStep5/>
          </div>
          <div v-if="formStore.currentStep === 6">
            <FormStep6/>
          </div>
          <div v-if="formStore.currentStep === 7">
            <FormStep7/>
          </div>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>

</template>

<script>
import {useApplicationStore} from "@/stores/ApplicationStore";
import {useFormStore} from "@/stores/FormStore";
import ProgressBar from "@/components/ProgressBar.vue";
import FormStep1 from "@/components/form/FormStep1.vue";
import FormStep2 from "@/components/form/FormStep2.vue";
import PageHeader from "@/components/PageHeader.vue";
import HomeContent from "@/components/HomeContent.vue"
import FormStep3 from "@/components/form/FormStep3.vue";
import FormStep4 from "@/components/form/FormStep4.vue";
import FormStep5 from "@/components/form/FormStep5.vue";
import FormStep6 from "@/components/form/FormStep6.vue";
import FormStep7 from "@/components/form/FormStep7.vue";
import {watch} from "vue";

export default {
  components: {
    FormStep7,
    FormStep6,
    FormStep5,
    FormStep4,
    FormStep3,
    PageHeader,
    HomeContent,
    FormStep1,
    FormStep2,
    ProgressBar,
  },

  setup() {
    const applicationStore = useApplicationStore();
    const formStore = useFormStore();

    watch(() => formStore.currentStep, (value, oldValue)=>{
      if(oldValue === 1 && value === 2){
        formStore.submittedSteps.step2.selected = [];
      }
    })

    return {applicationStore, formStore}
  },

  async mounted(){
    console.log('mounted');
    await this.formStore.fetchPriceModelCsv();

  }
};


</script>


<style>

@import "./assets/css/global.css";

.v-application__wrap {
  min-height: 100vh !important;
  min-height: 100dvh !important;

}

.container-card-text{
  padding: 0;
}

.container-card-title{
  padding: 0;
}

.container-card {
  height: 100%;
  box-shadow: unset;
  background: #F8F9FA;
  padding-left: 16px;
  padding-right: 16px;
}

.v-toolbar {
  box-shadow: unset !important;
}

.v-main{
  background: #F8F9FA;
}


</style>
