<template>

  <div class="header-container">
    <p class="first-word main-color-1"> eva 3 &nbsp;</p>
    <p class="second-word main-color-2"> ambulant</p>
  </div>

</template>


<style scoped>

.header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0 16px 16px;
}

.first-word {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 600;
  font-size: 26px;
}

.second-word {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 300;
  font-size: 26px;
}

</style>


<script>

export default {}


</script>
