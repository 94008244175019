<template>
  <div class="home-container d-flex flex-column justify-center align-center main-color-background-gradient">

    <div class="ufo-standard">
      <p class="poppins semi-bold">Pflege</p>
    </div>

    <h1 class="center-text">
      Nutzervereinbarung <br>
      eva 3 ambulant
    </h1>

    <button @click="continueToForm()" class="button-big d-flex align-center justify-center">
      <span class="first-span">Loslegen</span>
      <img
          :src="require('@/assets/images/arrow-forward.svg')" alt="">
    </button>

  </div>


</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

  created() {
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('quoteId') && urlParams.has('salesId')) {
      this.formStore.quoteId = urlParams.get('quoteId');
      this.formStore.salesId = urlParams.get('salesId');
    }

  },
  methods: {
    continueToForm() {
      if (this.formStore.quoteId !== null && this.formStore.salesId !== null) {
        this.formStore.currentStep++;
      } else {
        alert('Keine URL Params gesetzt');
      }
    }
  }
}

</script>


<style scoped>

.home-container {
  height: 100%;
}

.first-span {
  margin-right: 8px;
}

.ufo-standard {
  margin-bottom: 32px;
}

img {
  width: 20px;
  height: 20px;
}

p {
  color: white;
}

h1 {
  margin-bottom: 64px;
  color: white;
}

.v-sheet {
  padding: 16px;
  background: #2E6F82;
  margin-bottom: 32px;
}

</style>
