<template>


  <div class="d-flex flex-column align-start" style="width: 25%">
    <h3 class="text-color" style="margin-bottom: 4px">{{ title }}</h3>
    <p class="b2">{{ textLineOne }} <br> {{ textLineTwo }}</p>

  </div>

</template>


<script>

export default {
  props: ['title', 'textLineOne', 'textLineTwo']
}


</script>
