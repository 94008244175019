<template>

  <div :style="{background: getDotColor(step)}" class="dot poppins semi-bold d-flex align-center justify-center">
    {{ step }}
  </div>

</template>


<script>

export default {

  props: {
    step: Number,
    currentStep: Number,
  },

  methods: {
    getDotColor(index) {
      if (index === this.currentStep) {
        return '#78C6B8';
      } else if (index < this.currentStep) {
        return '#2E6F82';
      } else if (index > this.currentStep) {
        return '#ADB5BD'
      }
    },
  }
}

</script>


<style>

.dot {
  min-width: 24px;
  height: 24px;
  color: white;
  border-radius: 50%;
}

</style>
