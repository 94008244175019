import { createApp } from 'vue'
import App from './App.vue'
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import Vue3Signature from "vue3-signature"
import {loadFonts} from "@/plugins/webfontloader";
import axios from 'axios';
// @ts-ignore
import papa from 'papaparse';

loadFonts().then();

// @ts-ignore
createApp(App).use(vuetify).use(pinia, axios, papa).use(Vue3Signature).mount('#app')
