<template>

  <div class="progress-bar-container d-flex flex-row">

    <div class="d-flex" :class="{'max-width': n<totalSteps}" v-for="n in totalSteps" :key="n">
      <ProgressBarItem :step="n" :current-step="currentStep"/>
      <v-divider thickness="2" style="margin-left: 15px; margin-right: 15px" v-if="n<totalSteps"
                 :color="getLineColor(n)" class="align-self-center"></v-divider>
    </div>
  </div>

</template>

<script>


import ProgressBarItem from "@/components/ProgressBarItem.vue";

export default {
  components: {ProgressBarItem},

  props: {
    totalSteps: Number,
    currentStep: Number
  },

  methods: {
    getLineColor(index) {
      if (index < this.currentStep) {
        return '#2E6F82';
      } else {
        return '#ADB5BD'
      }
    }
  }
}

</script>

<style scoped>


.v-timeline-divider__dot .v-timeline-divider__dot--size-default {
  width: 24px;
  height: 24px;
}

.max-width {
  width: 100%;
}

span {
  color: white;
}

.progress-bar-container {
  padding: 32px 16px;
  width: 100%;
}

</style>
