<template>

  <div class="d-flex pt-4 pb-4">
    <div style="flex-basis: 120px">
      <v-checkbox v-model="formStore.submittedSteps.step3.selectedIntense" :value="checkBoxLabel"
                  class="formStep3CheckBox" density="compact" hide-details="auto" :label="checkBoxLabel"></v-checkbox>
    </div>
    <div class="d-flex flex-column descriptionContainer mr-auto">
      <h2 class="mb-2">{{ descriptionHeader }}</h2>
      <p>{{ descriptionText }}</p>
    </div>

    <h3>{{ price }}</h3>
  </div>
</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {
  props: ['checkBoxLabel', 'descriptionHeader', 'descriptionText', 'price'],

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },
}


</script>


<style scoped>

.descriptionContainer {
  padding-top: 2px;
}

</style>
